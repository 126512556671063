import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import Typography from "@mui/material/Typography";
import "./style.css";

function PortfolioBlock(props) {
  const { image, source, title, description } = props;
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      margin={"30px"}
    >
      <Box component={"img"} src={image} alt={"mockup"} />
      <br></br>
      <Typography variant="h1" sx={{ fontSize: "2rem" }}>{title}</Typography>
      <Typography variant="body1" sx={{ fontSize: "1rem", textAlign: "center" }}>{description}</Typography>
      <Box
        className={"portfolio"}
        display={"flex"}
        flexDirection={"column"}
        gap={"0.5rem"}
        alignItems={"center"}
        fontSize={"1.5rem"}
        py={"0.5rem"} // Reduce padding to decrease the gap
      >
        <IconButton
          component="a"
          href={source}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textDecoration: "none",
            backgroundColor: "transparent",
            color: "inherit", // Inherit color to match the theme
            '&:hover': {
              bgcolor: "rgba(255, 255, 255, 0.1)", // Slight background change on hover for dark mode
            },
            fontSize: "1.2rem", // Increase font size for the text
          }}
        >
          <Typography
            variant="body1"
            className="gradient-text"
            sx={{ marginRight: "0.5rem", fontSize: "1.2rem" }} // Increase font size
          >
            View Project
          </Typography>
          <GitHubIcon sx={{ fontSize: "1.5rem" }} /> {/* Increase icon size */}
        </IconButton>
        {/* <Box
          display={"flex"}
          alignItems={"center"}
        >
          <Typography variant="body1" className="gradient-text" sx={{ marginRight: "0.5rem" }}>View Project</Typography>
          <IconButton
            component="a"
            href={source}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "inherit", // Inherit color to match the theme
              bgcolor: "transparent", // Transparent background
              '&:hover': {
                bgcolor: "rgba(255, 255, 255, 0.1)", // Slight background change on hover for dark mode
              },
            }}
          >
            <GitHubIcon />
          </IconButton>
        </Box> */}
      </Box>
    </Box>
  );
}

export default PortfolioBlock;