import React from "react";
import Style from "./Home.module.scss";
import me from "../../img/me.png";
import classNames from "classnames";
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import { Box } from "@mui/material";
import { info } from "../../info/Info";

export default function Home() {
  return (
    <Box
      component={"main"}
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={"center"}
      justifyContent={"center"}
      minHeight={"calc(100vh - 175px)"}
    >
      <Box
        className={classNames(Style.avatar, Style.shadowed)}
        alt={"portrait"}
        style={{ background: info.gradient }}
        component={"img"}
        src={me}
        width={{ xs: "35vh", md: "40vh" }}
        height={{ xs: "35vh", md: "40vh" }}
        borderRadius={"50%"}
        p={"0.75rem"}
        mb={{ xs: "1rem", sm: 0 }}
        mr={{ xs: 0, md: "2rem" }}
      />
      <Box>
        <h1>
          Hi, I'm{" "}
          <span
            style={{
              background: info.gradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {info.firstName}
          </span>
          <span className={Style.hand}>✋🏽</span>
        </h1>
        <h2>I'm {info.position}</h2>
        <Box component={"ul"} p={"0.8rem"}>
          {info.miniBio.map((bio, index) => (
            <EmojiBullet key={index} emoji={bio.emoji} text={bio.text} />
          ))}
        </Box>
      {/* <Box
        maxWidth={{ xs: "100%", md: "38%" }} // Set maxWidth to control paragraph width
        p={{ xs: "0.5rem", md: "1rem" }}
      >
        <h1>
          Hi, I'm{" "}
          <span
            style={{
              background: info.gradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {info.firstName}
          </span>
          <span className={classNames(Style.hand)}>✋🏽</span>
        </h1>
        <h2>I'm {info.position}</h2>
        <Box className={Style.summary}>
          {info.summary.split("\n").map((line, index) => (
            <Typography key={index} component="p" variant="body1" className={Style.summaryText}>
              {line}
            </Typography>
          ))}
        </Box> */}
        {/* <Box className={Style.summary}>
          <Typography component="p" variant="body1" className={Style.summaryText}>
            {info.summary}
          </Typography>
        </Box> */}

        {/* <p style={{ fontSize: "1.2rem", margin: "1rem 0" }}>{info.summary}</p> */}
        <Box
          display={"flex"}
          gap={"1.5rem"}
          justifyContent={"center"}
          fontSize={{ xs: "1.5rem", md: "2rem" }}
          mt={"1rem"} // Add margin-top for spacing above social icons
        >
          {info.socials.map((social, index) => (
            <SocialIcon
              key={index}
              link={social.link}
              icon={social.icon}
              label={social.label}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
